<template>
  <div class="index">
    <dv-full-screen-container>

      <top-header />

      <div class="main-content">
        <digital-flop ref="titles" />

        <div class="block-left-right-content">
          <ranking-board ref="leftRank" style="height: 96%;"/>

          <div class="block-top-bottom-content">
            <div class="block-top-content">
              <rose-chart ref="rose" />

              <water-level-chart ref="water" />

              <scroll-board ref="board" />
            </div>

            <cards ref="cards" />

            <div class="block-bottom">
              <div @click="updateConf">
                <dv-decoration-3 style="width:350px;" />
              </div>
              <input class="upload-data" type="file" accept=".xlsx" @change="upload">
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from './topHeader'
import digitalFlop from './digitalFlop'
import rankingBoard from './rankingBoard'
import roseChart from './roseChart'
import waterLevelChart from './waterLevelChart'
import scrollBoard from './scrollBoard'
import cards from './cards'

var XLSX = require("xlsx")

export default {
  components: {
    topHeader,
    digitalFlop,
    rankingBoard,
    roseChart,
    waterLevelChart,
    scrollBoard,
    cards
  },
  data () {
    return {
      titles: [
        '全国文化大数据交易中心',
        '文化项目孵化中心',
        '国有文化产权交易业务',
      ],
      conf: null
    }
  },
  methods: {
    updateConf () {
      console.log('---- upload config ----')
      this.$el.querySelector('.upload-data').click()
    },
    upload (e) {
      const _this = this
      const f = e.target.files[0]
      const reader = new FileReader()
      reader.onload = function (e) {
        const d = e.target.result
        const wb = XLSX.read(d, { type: 'binary' })

        const s1 = XLSX.utils.sheet_to_json(wb.Sheets[_this.titles[0]])
        const s2 = XLSX.utils.sheet_to_json(wb.Sheets[_this.titles[1]])
        const s3 = XLSX.utils.sheet_to_json(wb.Sheets[_this.titles[2]])

        // console.log(s1, s2, s3)
        if (!s1.length || !s2.length || !s3.length) return
        _this.handleData(s1, s2, s3)
        _this.updateAll()
      }
      reader.readAsArrayBuffer(f)
    },
    handleData (s1, s2, s3) {
      this.conf = {}
      this.conf.titles = this.titles

      const d1 = []
      const d2 = []
      const d3 = []
      this.conf.rank = {d1, d2, d3}
      for (let i = 1; i <= 10; i++) {
        d1.push({ name: s1[i].B, value: s1[i].C})
        d2.push({ name: s2[i].B, value: s2[i].C})
        d3.push({ name: s3[i].B, value: s3[i].C})
      }

      const r1 = []
      const r2 = []
      const r3 = []
      this.conf.rose = {r1, r2, r3}
      for (let i = 13; i <= 22; i++) {
        if (s1[i].B) r1.push({ name: s1[i].B, value: s1[i].C})
        if (s2[i].B) r2.push({ name: s2[i].B, value: s2[i].C})
        if (s3[i].B) r3.push({ name: s3[i].B, value: s3[i].C})
      }

      this.conf.water = {
        w1: { type: s1[25].B, count: s1[25].C, per: s1[25].D },
        w2: { type: s2[25].B, count: s2[25].C, per: s2[25].D },
        w3: { type: s3[25].B, count: s3[25].C, per: s3[25].D }
      }

      const b1 = []
      const b2 = []
      const b3 = []
      this.conf.board = {b1, b2, b3}
      for (let i = 28; i <= 37; i++) {
        b1.push([s1[i].B, s1[i].C, s1[i].D, s1[i].E])
        b2.push([s2[i].B, s2[i].C, s2[i].D, s2[i].E])
        b3.push([s3[i].B, s3[i].C, s3[i].D, s3[i].E])
      }

      const row1 = {ring: [], lineX: [], lineY: []}
      const row2 = {ring: [], lineX: [], lineY: []}
      const row3 = {ring: [], lineX: [], lineY: []}
      this.conf.cards = {row1, row2, row3}
      for (let i = 40; i <= 49; i++) {
        if (s1[i].B) row1.ring.push({ name: s1[i].B, value: s1[i].C})
        if (s2[i].B) row2.ring.push({ name: s2[i].B, value: s2[i].C})
        if (s3[i].B) row3.ring.push({ name: s3[i].B, value: s3[i].C})
      }
      for (let i = 52; i <= 57; i++) {
        if (s1[i].B) row1.lineX.push(s1[i].B) && row1.lineY.push(s1[i].C)
        if (s2[i].B) row2.lineX.push(s2[i].B) && row2.lineY.push(s2[i].C)
        if (s3[i].B) row3.lineX.push(s3[i].B) && row3.lineY.push(s3[i].C)
      }
      row1.userAll = s1[60].B
      row1.userNew = s1[60].C
      row2.userAll = s2[60].B
      row2.userNew = s2[60].C
      row3.userAll = s3[60].B
      row3.userNew = s3[60].C
      row1.itemAll = s1[63].B
      row1.itemNew = s1[63].C
      row2.itemAll = s2[63].B
      row2.itemNew = s2[63].C
      row3.itemAll = s3[63].B
      row3.itemNew = s3[63].C
    },
    updateAll () {
      this.$refs.titles.update(this.conf.titles)
      this.$refs.leftRank.update(this.conf.rank)
      this.$refs.rose.update(this.conf.rose)
      this.$refs.water.update(this.conf.water)
      this.$refs.board.update(this.conf.board)
      this.$refs.cards.update(this.conf.cards)
    }
  }
}
</script>

<style lang="scss">
.index {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('../assets/bg.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .block-top-content {
    height: 50%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
  }

  .block-bottom {
    display: flex;
    justify-content: flex-end;
    .upload-data {
      display: none;
    }
  }
}
</style>
