<template>
  <div id="water-level-chart">
    <div class="water-level-chart-title">{{curr ? '累计服务机构数量' : '专网及跨域交易量'}}</div>

    <div class="water-level-chart-details">
      <span>{{count}}</span>{{curr?'家':'万元'}}
    </div>

    <div class="chart-container">
      <div class="water-level-chart-type">{{type}}</div>
      <dv-water-level-pond :config="config" />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      curr: 0,
      d: [],
      type: '--',
      count: 0,
      config: { data: [50], shape: 'round', waveHeight: 20, waveNum: 2 }
    }
  },
  methods: {
    update (d) {
      this.d = [
        { $type: d.w1.type, $count: d.w1.count, data: [d.w1.per], shape: 'round', waveHeight: 20, waveNum: 2 },
        { $type: d.w2.type, $count: d.w2.count, data: [d.w2.per], shape: 'round', waveHeight: 20, waveNum: 2 },
        { $type: d.w3.type, $count: d.w3.count, data: [d.w3.per], shape: 'round', waveHeight: 20, waveNum: 2 },
      ]
      this.curr = -1
      this.toggle()
      clearInterval(this.timer)
      this.timer = setInterval(this.toggle, this.$root.time)
    },
    toggle () {
      this.curr++
      if (this.curr >= this.d.length) this.curr = 0
      const conf = this.d[this.curr]
      this.config = conf
      this.type = conf.$type
      this.count = conf.$count
    }
  },
  mounted () {
    this.timer = setInterval(this.toggle, this.$root.time)
  },
  created () {
    // backup blank data
    // const conf = { $type: '--', $count: 0, data: [50], shape: 'round', waveHeight: 20, waveNum: 2 }
    // this.d.push(conf)
    // this.d.push(conf)
    // this.d.push(conf)

    // temp data 0606
    const d = {"w1":{"type":"文化资源数据","count":20,"per":100},"w2":{"type":"文化企业","count":30,"per":100},"w3":{"type":"文化企业","count":394,"per":100}}
    this.d = [
      { $type: d.w1.type, $count: d.w1.count, data: [d.w1.per], shape: 'round', waveHeight: 20, waveNum: 2 },
      { $type: d.w2.type, $count: d.w2.count, data: [d.w2.per], shape: 'round', waveHeight: 20, waveNum: 2 },
      { $type: d.w3.type, $count: d.w3.count, data: [d.w3.per], shape: 'round', waveHeight: 20, waveNum: 2 },
    ]
    this.curr = -1
    this.toggle()
  }
}
</script>

<style lang="scss">
#water-level-chart {
  width: 20%;
  box-sizing: border-box;
  margin-left: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  display: flex;
  flex-direction: column;

  .water-level-chart-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: center;
  }

  .water-level-chart-details {
    height: 15%;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: flex-end;

    span {
      font-size: 35px;
      font-weight: bold;
      color: #58a1ff;
      margin: 0 5px;
      margin-bottom: -5px;
    }
  }

  .chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .water-level-chart-type {
      z-index: 10;
      width: 100%;
      font-size: 18px;
      text-align: center;
      position: absolute;
      top: 84px;
    }
  }

  .dv-water-pond-level {
    max-width: 90%;
    width: 200px;
    height: 200px;
    border: 10px solid #19c3eb;
    border-radius: 50%;

    ellipse {
      stroke: transparent !important;
    }

    text {
      font-size: 40px;
    }
  }
}
</style>
