<template>
  <div id="digital-flop">
    <div
      class="digital-flop-item"
      :class="{curr: idx === curr}"
      v-for="(one, idx) in d"
      :key="idx">
      <div class="digital-flop-title">{{one}}</div>
    </div>
    <dv-decoration-10 />
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      curr: 0,
      d: [
        '全国文化大数据交易中心',
        '文化项目孵化中心',
        '国有文化产权交易业务',
      ]
    }
  },
  methods: {
    update (d) {
      this.d = d
      this.curr = 0
      clearInterval(this.timer)
      this.timer = setInterval(this.toggle, this.$root.time)
    },
    toggle () {
      this.curr++
      if (this.curr >= this.d.length) this.curr = 0
    }
  },
  mounted () {
    this.timer = setInterval(this.toggle, this.$root.time)
  }
}
</script>

<style lang="scss">
#digital-flop {
  position: relative;
  height: 7%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);

  .dv-decoration-10 {
    position: absolute;
    width: 99%;
    left: .5%;
    height: 5px;
    bottom: 0px;
  }

  .digital-flop-item {
    width: 33%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid rgb(6, 30, 93);
    border-right: 3px solid rgb(6, 30, 93);
    &.curr {
      background: rgba(255, 255, 255, .15);
    }
  }

  .digital-flop-title {
    font-size: 20px;
    margin-bottom: 4px;
  }
}
</style>
