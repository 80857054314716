<template>
  <div id="ranking-board">
    <div class="ranking-board-title">交易金额</div>
    <dv-scroll-ranking-board :config="config" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      curr: 0,
      d: [],
      config: null
    }
  },
  methods: {
    update (d) {
      this.d = [
        { data: d.d1, rowNum: 10, unit: '万元' },
        { data: d.d2, rowNum: 10, unit: '万元' },
        { data: d.d3, rowNum: 10, unit: '万元' },
      ]
      this.curr = 0
      this.config = this.d[0]
      clearInterval(this.timer)
      this.timer = setInterval(this.toggle, this.$root.time)
    },
    toggle () {
      this.curr++
      if (this.curr >= this.d.length) this.curr = 0
      this.config = this.d[this.curr]
    }
  },
  mounted () {
    this.timer = setInterval(this.toggle, this.$root.time)
  },
  created () {
    // temp data 0606
    const d1 = [{"name":"十大元帅等","value":20},{"name":"十大名医等","value":20},{"name":"十大名山等","value":20},{"name":"十大戏曲等","value":20},{"name":"十大名花等","value":20},{"name":"皇帝龙袍图等","value":1},{"name":" --","value":0},{"name":" --","value":0},{"name":" --","value":0},{"name":" --","value":0}]
    const d2 = [{"name":"城乡文化IP运营事业部","value":34120},{"name":"影视音像事业部","value":533},{"name":"乡村振兴事业部","value":53.15},{"name":"视觉创意数字版权创新事业部","value":52.9},{"name":"数字经济事业部","value":8.8},{"name":"新国潮事业部","value":2},{"name":" --","value":0},{"name":" --","value":0},{"name":" --","value":0},{"name":" --","value":0}]
    const d3 = [{"name":"华夏电影发行有限责任公司","value":33608.4476},{"name":"华夏城视网络电视股份有限公司","value":17852.87},{"name":"深圳宜和股份有限公司","value":12558},{"name":"新华互联电子商务有限责任公司","value":10848.8702},{"name":"深圳市天华世纪传媒有限公司","value":10088.6471},{"name":"中环国投（北京）投资有限公司","value":5000.0001},{"name":"环球合一网络技术（北京）股份有限公司","value":3785},{"name":"明博教育科技股份有限公司","value":3725},{"name":"中译语通文娱科技（青岛）有限公司","value":2909},{"name":"中国工信出版传媒集团有限责任公司","value":2287.313}]

    this.d.push({ data: d1, rowNum: 10, unit: '万元' })
    this.d.push({ data: d2, rowNum: 10, unit: '万元' })
    this.d.push({ data: d3, rowNum: 10, unit: '万元' })
    this.config = this.d[0]

    // backup blank data
    // const conf = {
    //   data: [
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //     { name: '--', value: 0 },
    //   ],
    //   rowNum: 10,
    //   unit: '万元'
    // }
    // const config = JSON.stringify(conf)
    // this.d.push(JSON.parse(config))
    // this.d.push(JSON.parse(config))
    // this.d.push(JSON.parse(config))
    // this.config = this.d[0]
  }
}
</script>

<style lang="scss">
#ranking-board {
  width: 20%;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;
  padding: 0px 30px;

  .ranking-board-title {
    font-weight: bold;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .dv-scroll-ranking-board {
    flex: 1;
    .ranking-column {
      border-color: rgba(128, 179, 255, .5);
      .inside-column {
        background-color: #03d3ec;
      }
    }
  }
}
</style>
