<template>
  <div id="rose-chart">
    <div class="rose-chart-title">交易项目类别</div>
    <dv-charts :option="option" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      curr: 0,
      d: [],
      base: '',
      option: null
    }
  },
  methods: {
    update (d) {
      const d1 = JSON.parse(this.base)
      const d2 = JSON.parse(this.base)
      const d3 = JSON.parse(this.base)
      d1.series[0].data = d.r1
      d2.series[0].data = d.r2
      d3.series[0].data = d.r3
      this.d = [d1, d2, d3]
      this.curr = 0
      this.option = this.d[0]
      clearInterval(this.timer)
      this.timer = setInterval(this.toggle, this.$root.time)
    },
    toggle () {
      this.curr++
      if (this.curr >= this.d.length) this.curr = 0
      this.option = this.d[this.curr]
    }
  },
  mounted () {
    this.timer = setInterval(this.toggle, this.$root.time)
  },
  created () {
    const opt = {
      series: [
        {
          type: 'pie',
          radius: '50%',
          roseSort: false,
          data: [
            { name: '数据', value: 50 },
            { name: '其他', value: 50 }
          ],
          insideLabel: {
            show: false
          },
          outsideLabel: {
            formatter: '{name} {percent}%',
            labelLineEndLength: 20,
            style: {
              fill: '#fff'
            },
            labelLineStyle: {
              stroke: '#fff'
            }
          },
          roseType: true
        }
      ],
      color: ['#da2f00', '#fa3600', '#ff4411', '#ff724c', '#541200', '#801b00', '#a02200', '#5d1400', '#b72700']
    }
    this.base = JSON.stringify(opt)

    // temp data 0606
    const d1 = JSON.parse(this.base)
    const d2 = JSON.parse(this.base)
    const d3 = JSON.parse(this.base)
    d1.series[0].data = [{"name":"文化资源数据","value":100},{"name":"其他","value":0}]
    d2.series[0].data = [{"name":"数字版权","value":34415.7},{"name":"乡村振兴土地使用权","value":301},{"name":"古建资产所有权","value":53.15}]
    d3.series[0].data = [{"name":"产权转让","value":71826.5197},{"name":"增资交易","value":47356.3523},{"name":"增资扩股","value":5296.8018},{"name":"资产转让","value":373.8211}]
    this.d = [d1, d2, d3]
    this.option = this.d[0]

    // backup blank data
    // this.d.push(opt)
    // this.d.push(opt)
    // this.d.push(opt)
    // this.option = this.d[0]
  }
}
</script>

<style lang="scss">
#rose-chart {
  width: 30%;
  height: 100%;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, .5);
  box-sizing: border-box;

  .rose-chart-title {
    height: 50px;
    font-weight: bold;
    text-indent: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .dv-charts-container {
    height: calc(100% - 50px);
  }
}
</style>
