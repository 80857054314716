<template>
  <div id="cards">
    <div class="card-item card-bigger">
      <div class="card-header">
        <div class="card-header-title">标的类型</div>
      </div>
      <dv-active-ring-chart class="ring-charts3" :config="ring" />
    </div>

    <div class="card-item card-large">
      <div class="card-header">
        <div class="card-header-title">月度交易金额统计</div>
      </div>
      <dv-charts class="bar-chart" :option="line" />
    </div>

    <div class="card-item">
      <div class="card-header">
        <div class="card-header-title">{{d[curr].countTitle}}</div>
      </div>
      <dv-charts class="ring-charts" :option="user" />
      <div class="hold-ring" v-if="num1.number[0]<0">
        <div class="hold-tx">待统计</div>
      </div>
      <div class="card-footer">
        <div class="card-footer-item">
          <div class="footer-title">累计数量</div>
          <div class="footer-detail">
            <div class="hold c-r" v-if="total1.number[0]<0">待统计</div>
            <dv-digital-flop v-else :config="total1" style="width:100%;height:35px;" />
          </div>
        </div>
        <div class="card-footer-item">
          <div class="footer-title">当月新增</div>
          <div class="footer-detail">
            <div class="hold" v-if="num1.number[0]<0">待统计</div>
            <dv-digital-flop v-else :config="num1" style="width:100%;height:35px;" />
          </div>
        </div>
      </div>
    </div>

    <div class="card-item">
      <div class="card-header">
        <div class="card-header-title">{{d[curr].totalTitle}}</div>
      </div>
      <dv-charts class="ring-charts" :option="item" />
      <div class="hold-ring" v-if="num2.number[0]<0">
        <div class="hold-tx">待统计</div>
      </div>
      <div class="card-footer">
        <div class="card-footer-item">
          <div class="footer-title">{{d[curr].subTitle}}</div>
          <div class="footer-detail">
            <div class="hold c-r" v-if="total2.number[0]<0">待统计</div>
            <dv-digital-flop v-else :config="total2" style="width:100%;height:35px;" />
          </div>
        </div>
        <div class="card-footer-item">
          <div class="footer-title">当月新增</div>
          <div class="footer-detail">
            <div class="hold" v-if="num2.number[0]<0">待统计</div>
            <dv-digital-flop v-else :config="num2" style="width:100%;height:35px;" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      timer: null,
      curr: 0,
      d: [],
      baseRow: '',
      baseRing: null,
      baseLine: null,
      baseItem: null,
      baseTotal: null,
      baseNum: null,

      ring: null,
      line: null,
      user: null,
      item: null,
      total1: null,
      total2: null,
      num1: null,
      num2: null,
      all: ['ring', 'line', 'user', 'item', 'total1', 'total2', 'num1', 'num2']
    }
  },
  methods: {
    update (d, isTempData = false) {
      const row1 = JSON.parse(this.baseRow)
      const row2 = JSON.parse(this.baseRow)
      const row3 = JSON.parse(this.baseRow)
      row1.ring.data = d.row1.ring
      row2.ring.data = d.row2.ring
      row3.ring.data = d.row3.ring
      row1.line.xAxis.data = d.row1.lineX
      row1.line.series[0].data = d.row1.lineY
      row2.line.xAxis.data = d.row2.lineX
      row2.line.series[0].data = d.row2.lineY
      row3.line.xAxis.data = d.row3.lineX
      row3.line.series[0].data = d.row3.lineY
      row1.user.series[0].data[0].value = Number(d.row1.userNew / d.row1.userAll * 100) || 0
      row2.user.series[0].data[0].value = Number(d.row2.userNew / d.row2.userAll * 100) || 0
      row3.user.series[0].data[0].value = Number(d.row3.userNew / d.row3.userAll * 100) || 0
      row1.item.series[0].data[0].value = Number(d.row1.itemNew / d.row1.itemAll * 100) || 0
      row2.item.series[0].data[0].value = Number(d.row2.itemNew / d.row2.itemAll * 100) || 0
      row3.item.series[0].data[0].value = Number(d.row3.itemNew / d.row3.itemAll * 100) || 0
      row1.total1.number = [d.row1.userAll]
      row2.total1.number = [d.row2.userAll]
      row3.total1.number = [d.row3.userAll]
      row1.total2.number = [d.row1.itemAll]
      row2.total2.number = [d.row2.itemAll]
      row3.total2.number = [d.row3.itemAll]
      row1.num1.number = [d.row1.userNew]
      row2.num1.number = [d.row2.userNew]
      row3.num1.number = [d.row3.userNew]
      row1.num2.number = [d.row1.itemNew]
      row2.num2.number = [d.row2.itemNew]
      row3.num2.number = [d.row3.itemNew]
      row1.countTitle = '对接咨询数字化转型服务机构量'
      row2.countTitle = '对接咨询及服务机构量'
      row3.countTitle = '用户量'
      row1.totalTitle = '数据资产及进场标的(含版权数据)'
      row2.totalTitle = '服务项目量'
      row3.totalTitle = '服务机构量'
      row1.subTitle = '累计数量'
      row2.subTitle = '累计项目'
      row3.subTitle = '累计机构'

      this.d = [row1, row2, row3]
      this.curr = 0
      this.all.forEach(k => {
        this[k] = this.d[0][k]
      })

      if (isTempData) return

      clearInterval(this.timer)
      this.timer = setInterval(this.toggle, this.$root.time)
    },
    toggle () {
      this.curr++
      if (this.curr >= this.d.length) this.curr = 0
      const row = this.d[this.curr]
      this.all.forEach(k => {
        this[k] = row[k]
      })
    }
  },
  mounted () {
    this.timer = setInterval(this.toggle, this.$root.time)
  },
  created () {
    const row = {}
    this.baseRing = {
      data: [
        { name: '--', value: 100 },
      ],
      radius: '70%',
      activeRadius: '76%',
      lineWidth: 24,
      digitalFlopStyle: {
        fontSize: 16
      }
    }
    row.ring = Object.assign({}, this.baseRing)

    this.baseLine = JSON.stringify({
      xAxis: {
        data: ['01', '02', '03', '04', '05', '06'],
        axisLine: {
          style: {
            stroke: 'rgba(255, 255, 255, 0.5)'
          }
        },
        axisLabel: {
          style: {
            fill: '#eee',
            fontSize: 12
          }
        },
        axisTick: {
          show: true,
          style: {
            stroke: '#eee'
          }
        },
      },
      yAxis: {
        data: 'value',
        name: '交易金额/万元',
        axisLine: {
          style: {
            stroke: 'rgba(255, 255, 255, 0.5)'
          }
        },
        nameGap: 30,
        nameTextStyle: {
          fill: '#ddd',
          fontSize: 13
        },
        axisLabel: {
          style: {
            fill: '#eee',
            fontSize: 12
          }
        },
        splitLine: {
          style: {
            stroke: 'rgba(255, 255, 255, 0.2)',
          }
        },
      },
      series: [
        {
          data: [20, 30, 40, 30, 40, 50],
          type: 'line',
          lineStyle: {
            lineWidth: 2
          },
          lineArea: {
            show: true,
            gradient: ['rgba(3, 211, 236, 1)', 'rgba(3, 211, 236, 0.3)']
          }
        }
      ]
    })
    row.line = JSON.parse(this.baseLine)

    this.baseItem = JSON.stringify({
      series: [
        {
          type: 'gauge',
          startAngle: -Math.PI / 2,
          endAngle: Math.PI * 1.5,
          arcLineWidth: 13,
          radius: '80%',
          data: [
            { name: '新增占比', value: 30 }
          ],
          axisLabel: {
            show: false
          },
          axisTick: {
            show: false
          },
          pointer: {
            show: false
          },
          backgroundArc: {
            style: {
              stroke: '#224590'
            }
          },
          details: {
            show: true,
            formatter: '新增占比{value}%',
            style: {
              fill: '#1ed3e5',
              fontSize: 20
            }
          }
        }
      ],
      color: ['#03d3ec']
    })
    row.user = JSON.parse(this.baseItem)
    row.item = JSON.parse(this.baseItem)

    this.baseTotal = {
      number: [0],
      style: {
        fontSize: 20,
        fill: '#ea6027',
        fontWeight: 'bold'
      }
    }
    row.total1 = Object.assign({}, this.baseTotal)
    row.total2 = Object.assign({}, this.baseTotal)

    this.baseNum = {
      number: [0],
      style: {
        fontSize: 20,
        fill: '#26fcd8',
        fontWeight: 'bold'
      }
    }
    row.num1 = Object.assign({}, this.baseNum)
    row.num2 = Object.assign({}, this.baseNum)

    // backup blank data
    // this.d.push(row)
    // this.d.push(row)
    // this.d.push(row)
    // this.all.forEach(k => {
    //   this[k] = row[k]
    // })
    this.baseRow = JSON.stringify(row)

    // temp data 0606
    const d = {"row1":{"ring":[{"name":"文化资源数据","value":100}],"lineX":["2022-12","2023-01","2023-02","2023-03","2023-04"],"lineY":[20,20,0,41,20],"userAll":71,"userNew":20,"itemAll":5057,"itemNew":276},"row2":{"ring":[{"name":"数字版权","value":30}],"lineX":["2023-01","2023-02","2023-03","2023-04"],"lineY":[116.8,165,34352,30],"userAll":34,"userNew":1,"itemAll":533,"itemNew":1},"row3":{"ring":[{"name":"产权转让","value":100}],"lineX":["2023-01","2023-02","2023-03","2023-04"],"lineY":[0,0,178,394],"userAll":100,"userNew":1,"itemAll":77,"itemNew":0}}
    this.update(d, true)
  }
}
</script>

<style lang="scss">
#cards {
  display: flex;
  justify-content: space-between;
  height: 46%;

  .card-item {
    background-color: rgba(6, 30, 93, 0.5);
    border-top: 2px solid rgba(1, 153, 209, .5);
    width: 19%;
    display: flex;
    flex-direction: column;
    &.card-bigger {
      width: 23%;
    }
    &.card-large {
      width: 35%;
    }
  }

  .card-header {
    display: flex;
    height: 20%;
    align-items: center;
    justify-content: center;

    .card-header-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .ring-charts {
    height: 55%;
  }

  .ring-charts3 {
    width: 100%;
    height: 75%;
  }

  .bar-chart {
    height: 80%;
  }

  .card-footer {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .card-footer-item {
    padding: 5px 10px 0px 10px;
    box-sizing: border-box;
    width: 40%;
    background-color: rgba(6, 30, 93, 0.7);
    border-radius: 3px;

    .footer-title {
      font-size: 15px;
      margin-bottom: 5px;
    }

    .footer-detail {
      font-size: 20px;
      color: #1294fb;
      display: flex;
      font-size: 18px;
      align-items: center;
    }
  }

  .hold {
    width: 100%;
    height: 35px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #26fcd8;
    &.c-r {
      color: #ea6027;
    }
  }

  .hold-ring {
    height: 0;
    position: relative;
    top: -32%;
    text-align: center;
    .hold-tx {
      display: inline-block;
      width: 55%;
      line-height: 34px;
      font-size: 20px;
      font-weight: bold;
      color: #1ed3e5;
      background: #041135;
    }
  }
}
</style>
